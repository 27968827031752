import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function LandingPageFooter() {
    const { t, i18n } = useTranslation();

    return <>
        <div className="bg-side-color py-4 ">
          <div className="container">
            <div className="row">
                <div className="col-md-4 d-flex flex-column align-items-center text-center">
                    <img src="/Images/footer-logo.png" className="land-logo mb-3 " alt="Logo" />
                    <h4 className="text-white  ">IG WAY</h4>
                    <h4 className="text-white">{t("follow")}</h4>
                        <div className="d-flex justify-content-around mt-4">
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center me-2">
                                <i className="fa-brands fa-facebook-f"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center me-2">
                                <i className="fa-brands fa-instagram"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center me-2">
                                <i className="fa-brands fa-linkedin-in"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center">
                                <i className="fa-brands fa-youtube"></i>
                            </div>    
                        </div>
                        </div>
                        <div className="col-md-8 text-white">
                            <div className="questions-responsive">
                                <h3>{t("questions")}</h3>
                                <p className="opacity-75">{t("leaveQuestion") }</p>
                            </div>
                            <form action="">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <input type="text" className="form-control" id="fullName" placeholder={t("enterFullname")} />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <input type="email" className="form-control" id="emailAddress" placeholder={t("enterEmail")} />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <textarea className="form-control" id="question" placeholder={t("writeQuestions")} rows="3"></textarea>
                                    </div>
                                    <div className="w-100 d-flex justify-content-end">
                                        <Link className="btn btn-warning mt-3 px-5">{t("submit")}</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                </div>
          </div>
        </div>
        <div className=" bg-side-color d-flex justify-content-center align-items-center copywrite position-relative ">      
                <p className="text-white mt-3">{t("rights") }<span className="text-warning">IG WAY</span> </p>
        </div> 

</>


     }
