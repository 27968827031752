import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Styles from './Wallet.module.css';
import { Circles } from "react-loader-spinner";
import ReactHelmet from '../ReactHelmet/ReactHelmet';
import { usePost } from '../usePost';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { useWallet } from '../../WalletContext';

export default function Wallet() {
    const { t } = useTranslation();
    const [transactionType, setTransactionType] = useState("Credit");
    const navigate = useNavigate();
    const [textToCopy, setTextToCopy] = useState('');
    const [copyStatus, setCopyStatus] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState([]);
    const userJSON = localStorage.getItem("user");
    const user = JSON.parse(userJSON);
    const { wallet, setWallet, error, fetchWalletData } = useWallet();
    const { makeRequest, loading } = usePost("https://back.ig-way.com/api/user/wallet/recharge");
    const [rechargeVodafone, setRechargeVodafone] = useState({
        payment_method: 3,
        user_id: user.id,
        phone_number: '',
        value: ''
    });

    const [rechargeCredit, setRechargeCredit] = useState({
        payment_method: 2,
        user_id: user.id,
        value: ''
    });

    const goBack = () => {
        navigate(-1);
    };


    const handleTransactionTypeChange = (type) => {
        setTransactionType(type);
    };

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getCards();
        getVodafone();
    }, []);

    useEffect(() => {
        fetchWalletData();
        getVodafone();
        console.log(wallet);
    }, [fetchWalletData]);

    const getVodafone = async () => {
        try {
            const token = localStorage.getItem("token");
            let { data } = await axios.get("https://back.ig-way.com/api/settings", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPhoneNumber([...data.data]);
            setRechargeCredit({ ...rechargeCredit });
            setRechargeVodafone({ ...rechargeVodafone, phone_number: data.data[0].value });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setIsLoading(false);
        }
    };

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000);
    };

    const getCards = async () => {
        try {
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handleRecharge = async (e) => {
        e.preventDefault();
        await makeRequest(rechargeVodafone);
        console.log(rechargeVodafone);
    };

    const handleCreditRecharge = async (e) => {
        e.preventDefault();
        await makeRequest(rechargeCredit);
        console.log(rechargeCredit);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        // Remove any non-numeric characters (except for decimal points)
        const numericValue = value.replace(/[^0-9.]/g, '');
    
        if (transactionType === "Credit") {
            setRechargeCredit(prevState => ({
                ...prevState,
                value: numericValue
            }));
        } else if (transactionType === "Vodafone Cash") {
            setRechargeVodafone(prevState => ({
                ...prevState,
                value: numericValue
            }));
        }
    };
    

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center spiner">
                <Circles height="80" width="80" color="#FFBC15" ariaLabel="circles-loading" wrapperStyle={{}} wrapperClass="" visible={true} />
            </div>
        );
    }

    return (
        <>
            <div className="container-fluid">
                <ReactHelmet title={`${t("myWallet")} | IG WAY`} />
                <div className="row">
                    <div className="col-md-9 mb-5">
                        <div className='d-flex align-items-center pb-3'>
                            <i onClick={goBack} className={`fa-solid left-arrow-checkout fa-arrow-left backIcon ms-3`}></i>
                            <p className={`ms-5 h1 fs-5 fw-bold alltext-black-color`}>{t("myWallet")}</p>
                        </div>
                        <div className='bg-icon-color ms-4 p-4 rounded-4 d-flex flex-row justify-content-between'>
                            <div>
                                <h4 className='alltext-black-color'>{t("balance")}</h4>
                            </div>
                            <div className='d-flex flex-row'>
                                <h4 className='pe-2 alltext-black-color'>{t("EGP")} |</h4>
                                <h4 className='alltext-black-color'>{wallet}</h4>
                            </div>
                        </div>
                        <div>
                            <h4 className='mt-4 ps-4 alltext-black-color'>{t("rechargeWallet")}</h4>
                            <div className='p-3'>
                                <div className='bg-white rounded-3 p-2 mb-3'>
                                    <label htmlFor="amount" className='fw-bold mb-2 alltext-side-color'>{t("amount")}</label>
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend">
                                            <span className={`input-group-text h-100 bg-transparent text-secondary fw-bold pe-4 ps-3 ${Styles.roundedLeft}`} id="inputGroup-sizing-lg">{t("EGP")}</span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control alltext-black-color"
                                            aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-lg"
                                            value={transactionType === "Credit" ? rechargeCredit.value : rechargeVodafone.value}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className={Styles.paymentMethod}>
                                    <div className={`d-flex justify-content-between w-100 pb-4 flex-wrap`}>
                                        <div className="row w-100">
                                            <div className="col-md-4">
                                                <button
                                                    className={`${Styles.transactionBtn} transactionBtn d-flex w-100 justify-content-center py-3 ${transactionType === "Credit" ? Styles.active : ""}`}
                                                    onClick={() => handleTransactionTypeChange("Credit")}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="credit"
                                                        id="credit"
                                                        value="Credit"
                                                        checked={transactionType === "Credit"}
                                                        onChange={() => handleTransactionTypeChange("Credit")}
                                                    />
                                                    <label htmlFor="credit">{t("debit/credit")}</label>
                                                </button>
                                            </div>
                                            <div className="col-md-4">
                                                <button
                                                    className={`${Styles.transactionBtn} transactionBtn d-flex justify-content-center w-100 py-3 ${transactionType === "Vodafone Cash" ? Styles.active : ""}`}
                                                    onClick={() => handleTransactionTypeChange("Vodafone Cash")}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="vodafone"
                                                        id="vodafone"
                                                        value="Vodafone Cash"
                                                        checked={transactionType === "Vodafone Cash"}
                                                        onChange={() => handleTransactionTypeChange("Vodafone Cash")}
                                                    />
                                                    <label htmlFor="vodafone">{t("vodafoneCash")}</label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {transactionType === "Credit" && (
                                    <div className="container mt-4 p-3 bg-white rounded-4 shadow ms-0" style={{ width: "93%" }}>
                                        <div className="w-100">
                                            {loading ? (
                                                <button
                                                type="submit" disabled
                                                className={`w-100 btn btn-warning mt-4 fw-bold py-2 alltext-side-color shadow`}
                                            >
                                                <i className="fa-solid fa-spinner fa-pulse"></i>
                                            </button>
                                            ) : (
                                                <button
                                                type="submit"
                                                onClick={handleCreditRecharge}
                                                className={`w-100 btn btn-warning mt-4 fw-bold py-2 alltext-side-color shadow`}
                                            >
                                                {t("charge")}
                                            </button>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {transactionType === "Vodafone Cash" && (
                                    <div className="container mt-4 p-3 bg-white rounded-4 shadow ms-0" style={{ width: "93%" }}>
                                        <div className="container mt-4 p-3 bg-white rounded-4 shadow ms-0">
                                            <label htmlFor="phone-number" className='form-label alltext-black-color h6'>{t("phone")}</label>
                                            <div className='position-relative'>
                                                <input
                                                    type="text"
                                                    name="phone-number"
                                                    id="phone-number"
                                                    className='form-control bg-light py-3 pe-5'
                                                    value={phoneNumber[0]?.value}
                                                    readOnly
                                                />
                                                <CopyToClipboard text={phoneNumber[0]?.value || ''} onCopy={onCopyText}>
                                                    <i className="fa-regular fa-copy position-absolute top-50 end-0 translate-middle pe-1" style={{ color: "#3d3d3d" }}></i>
                                                </CopyToClipboard>
                                                {copyStatus && <p className='text-success position-absolute top-50 end-0 translate-middle pe-1'>{t("copyNumber")}</p>}
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                           {loading ? (
                                             <button
                                             className={`mx-5 w-100 btn btn-warning mt-4 fw-bold py-2 alltext-side-color shadow`}
                                             disabled
                                         >
                                             <i className="fas fa-spinner fa-spin"></i>
                                         </button>
                                           ) : (
                                            <button
                                            className={`mx-5 w-100 btn btn-warning mt-4 fw-bold py-2 alltext-side-color shadow`}
                                            onClick={handleRecharge}
                                        >
                                            {t("charge")}
                                        </button>
                                           )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
