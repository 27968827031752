import React, { useEffect, useState } from 'react'
import $ from "jquery";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Circles } from "react-loader-spinner";
import CourseCompletedItem from '../CourseItem/CourseCompletedItem';



export default function CompletedCourses() {
  const { t } = useTranslation();

  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedBoards, setSelectedBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [completedCourses, setCompletedCourses] = useState([]);

  const handleCheckboxChange = (setter, value) => {
    setter((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };
  const getCourses = async () => {
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        "https://back.ig-way.com/api/user/completed-courses",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCompletedCourses([...data.data]);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCourses();
  }, []);
  // $(".drop-list").slideToggle();
  function handleSlideDown(event) {
    $(event.currentTarget).parent().find(".drop-list").slideToggle();

    // Toggle the arrow icon class to switch between down and up arrows
    $(event.currentTarget).find(".arrow-icon").toggleClass("up");
  }
    return <>
      <>
        {isLoading === false ?
    
          <div className="row mt-3 smallSizes">
            <div className="col-md-3 filterSmallSize">
              <div className=" shadow p-3 rounded-2 bg-white">
                <div className="d-flex justify-content-between ">
                  <div className="d-flex align-items-center">
                    <img src="/images/filter.png" className="   mx-2" alt="" />
                    <h5>{t("filter")}</h5>
                  </div>
                  <button
                    className="btn btn-link text-decoration-none text-warning"
                    onClick={() => {
                      setSelectedLevels([]);
                      setSelectedBoards([]);
                    }}
                  >
                    {t("reset")}
                  </button>
                </div>
                <hr className="w-75 mx-auto " />
                <form>

                  <div className="mb-3">
                    <label
                      onClick={handleSlideDown}
                      className="form-label btn border w-100 text-start d-flex justify-content-between align-items-center"
                    >
                      <p className="p-0 m-0">Levels</p>
                      <img
                        src="/Images/arrow-down.png"
                        className="search arrow-icon"
                        alt=""
                      />
                    </label>

                    <div className="drop-list hidden px-3 bg-light py-2 rounded-2 ">
                      <div className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                        <label className="form-check-label">Level 1</label>
                        <input
                          className="form-check-input rounded-circle"
                          type="checkbox"
                          value="Level 1"
                          onChange={() =>
                            handleCheckboxChange(setSelectedLevels, "Level 1")
                          }
                          checked={selectedLevels.includes("Level 1")}
                        />
                      </div>
                      <div className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                        <label className="form-check-label">Level 2</label>
                        <input
                          className="form-check-input rounded-circle"
                          type="checkbox"
                          value="Level 2"
                          onChange={() =>
                            handleCheckboxChange(setSelectedLevels, "Level 2")
                          }
                          checked={selectedLevels.includes("Level 2")}
                        />
                      </div>
                      <div className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                        <label className="form-check-label">Level 3</label>
                        <input
                          className="form-check-input rounded-circle"
                          type="checkbox"
                          value="Level 3"
                          onChange={() =>
                            handleCheckboxChange(setSelectedLevels, "Level 3")
                          }
                          checked={selectedLevels.includes("Level 3")}
                        />
                      </div>
                      <div className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                        <label className="form-check-label">Level 4</label>
                        <input
                          className="form-check-input rounded-circle"
                          type="checkbox"
                          value="Level 4"
                          onChange={() =>
                            handleCheckboxChange(setSelectedLevels, "Level 4")
                          }
                          checked={selectedLevels.includes("Level 4")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      onClick={handleSlideDown}
                      className="form-label btn border w-100 text-start d-flex justify-content-between align-items-center"
                    >
                      <p className="p-0 m-0">Board</p>
                      <img
                        src="/Images/arrow-down.png"
                        className="search arrow-icon"
                        alt=""
                      />
                    </label>
                    <div className="drop-list hidden px-3 bg-light py-2 rounded-2 ">
                      <div className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                        <label className="form-check-label">Board 1</label>
                        <input
                          className="form-check-input rounded-circle"
                          type="checkbox"
                          value="Board 1"
                          onChange={() =>
                            handleCheckboxChange(setSelectedBoards, "Board 1")
                          }
                          checked={selectedBoards.includes("Board 1")}
                        />
                      </div>
                      <div className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                        <label className="form-check-label">Board 2</label>
                        <input
                          className="form-check-input rounded-circle"
                          type="checkbox"
                          value="Board 2"
                          onChange={() =>
                            handleCheckboxChange(setSelectedBoards, "Board 2")
                          }
                          checked={selectedBoards.includes("Board 2")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100 text-center">
                    <button
                      type="submit"
                      className="btn border border-2 border-black  w-50 mx-auto"
                    >
                      {t("apply")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="d-flex align-items-center bg-white search-div shadow rounded-2 py-1 px-2 ">
                    <img src="/images/search.png" className="search" alt="" />
                    <input
                      type="text"
                      className="form-control px-2 border-0"
                      placeholder={t("search")}
                    />
                  </div>
                 
                </div>
                <div className="row"></div>
                {
                  completedCourses.length === 0
                  ?
                  <div className="col-md-12 mt-2">
                  <div className="text-center">
                    <img src="/images/Student stress-pana1.png" alt="" className='w-50' />
                    <h2 className="opacity-50">
                      {t("notCompleted")}
                    </h2>
                    <button className="btn btn-warning fw-bold mt-3 w-50 py-3 large-font">
                      {t("getStart")}
                    </button>
                  </div>
                  </div>
                    :

                  (completedCourses.map((course , index) => (
                    <CourseCompletedItem course={course} key={index} />
                  )))
                }
                
              </div>
            </div>
          </div>
          : <div className="d-flex justify-content-center align-items-center spiner">
          <Circles
            height="80"
            width="80"
            color="#FFBC15"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>}
    </>

    </>
}
