import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Circles } from "react-loader-spinner";
import { useTranslation } from 'react-i18next'; 
import ReactHelmet from '../../ReactHelmet/ReactHelmet';

export default function AttendanceTransactions() {
    const { t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [attendanceTransactions, setAttendanceTransactions] = useState(null);


    const getAttendanceTransactions = async (lessonId) => {
        setIsLoading(true);
        
        try {
            const token = localStorage.getItem("token");
            let { data } = await axios.get(
                `https://back.ig-way.com/api/user/transactions/attendance`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log(data.data);
            setAttendanceTransactions(data.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getAttendanceTransactions();
        
    }, []);
    return <>
     <ReactHelmet title={`${t('attendanceTransactions')} | IG WAY`} />

        {isLoading === true ?
        <div className="d-flex justify-content-center align-items-center spiner">
            <Circles
            height="80"
            width="80"
            color="#FFBC15"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />
        </div> :
            <div className='px-2 mx-md-5 p-4'>
                <div className="bg-white p-3 rounded-3 shadow">
                    <div className=''>
                        <div className="filter-container">
                        <div className="filter-header d-flex align-items-center">
                            <img src="/Images/filter.png" className='search me-2' alt="" />
                            <h5>{t('filter')}</h5>
                        </div>
                        <hr />
                            <form className='d-flex flex-column'>
                                <div className=" container-fluid">

                                    <div className="row w-100 mb-4">
                                        <div className="col-md-3">        
                                            <div className='d-flex flex-column'>    
                                                <label>{t('courseName')}</label>
                                                <select className='form-control'>
                                                    <option>{t('select')}</option>
                                                    {/* Add options here */}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">        
                                            <div className='d-flex flex-column'>    
                                                <label>{t('teacher')}</label>
                                                <select className='form-control'>
                                                    <option>{t('select')}</option>
                                                    {/* Add options here */}
                                                </select>
                                            </div>
                                        </div>
                                    
                                        <div className="col-md-3">        
                                            <div className='d-flex flex-column'>    
                                                <label>{t('from')}</label>
                                                <input type="date" className='form-control' />
                                            </div>
                                        </div>
                                        <div className="col-md-3">        
                                            <div className='d-flex flex-column'>    
                                                <label>{t('to')}</label>
                                                <input type="date"  className='form-control'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                <div className="row w-100">
                                        <div className="col-md-12 col-lg-2 offset-lg-10">
                                            <button className=' btn btn-warning w-100' variant="primary" type="submit">{t('apply')}</button>
                                        </div>
                                    </div>
                                </div>
                        </form>
                        </div>
                    </div>
                </div>
                <h4 className='mt-4'>{t("transactionList")}</h4>
                <div className="input-group mb-3 search-transaction">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t("search2")}
                        aria-label="Search"
                        aria-describedby="search-icon"
                    />
                    <div className="input-group-append h-100 bg-white">
                        <span className="input-group-text" id="search-icon">
                        <i className="fas fa-search p-2"></i>
                        </span>
                    </div>
                </div>
                <div className='bg-white shadow rounded-3 p-3  mb-3'> 
                    <table className='table  table-bordered text-center '>
                        <thead>
                            <tr>
                                <th  className='bg-light'>{t('id')}</th>
                                <th  className='bg-light'>{t('courseName')}</th>
                                <th  className='bg-light'>{t('date2')}</th>
                                <th  className='bg-light'>{t('amount2')}</th>
                                <th  className='bg-light'>{t('currency')}</th>
                                <th  className='bg-light'>{t('status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {attendanceTransactions.map((attendanceTransaction) => {    
                            return <tr>
                                    <td>{attendanceTransaction.id}</td>
                                    <td>mdo</td>
                                    <td>{attendanceTransaction.formatted_date}</td>
                                    <td> {attendanceTransaction.value}</td>
                                    <td>{attendanceTransaction.currency} {attendanceTransaction.value}</td>
                                    <td>{attendanceTransaction.status}</td>
                                </tr>
                            })}
                            
                        </tbody>
                    </table>
                </div>
            </div>
            }
    </>
}
