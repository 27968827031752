import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotificationItem from "../NotificationItem/NotificationItem";
import { useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const [notification, setNotification] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null); 
  const [navbarOpen, setNavbarOpen] = useState(false); 
  const location = useLocation();
  
  const handleToggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };
  const toggleDropdown = (dropdown) => {
    // If the clicked dropdown is already open, close it
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      // Otherwise, set the clicked dropdown as active and close others
      setActiveDropdown(dropdown);
      // Close other dropdowns
      if (dropdown !== "notification") {
        $("#notification-dropdown").slideUp("slow");
      }
      if (dropdown !== "language") {
        $("#language-dropdown").slideUp("slow");
      }
      if (dropdown !== "profile") {
        $(".logout-div").slideUp("slow");
      }
    }
  };
  

  function notiHandler() {
    toggleDropdown("notification");
    $("#notification-dropdown").slideToggle("slow");
    if (activeDropdown === "language") {
      $("#language-dropdown").slideUp("slow"); 
    }
  }
  function changeLanguage() {
    toggleDropdown("language");
    $("#language-dropdown").slideToggle("slow");
    if (activeDropdown === "notification") {
      $("#notification-dropdown").slideUp("slow"); 
    }
  }
  function profileHandler() {
    toggleDropdown("profile");
    $(".logout-div").slideToggle("slow"); 
    if (activeDropdown === "notification") {
      $("#notification-dropdown").slideUp("slow");
    }
    if (activeDropdown === "language") {
      $("#language-dropdown").slideUp("slow");
    }
  }
  
  //handle logout if user data is invalid
  const checkUserAndLogout = () => {
    const userJSON = localStorage.getItem("user");
    if (!userJSON) {
      logOut();
      return;
    }
    const user = JSON.parse(userJSON);
    if (!user || !user.firstName) {
      logOut();
    }
  };

  useEffect(() => {
    checkUserAndLogout();
  }, []);


  async function logOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  }

  let navigate = useNavigate();

  const getNotification = async () => {
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        "https://back.ig-way.com/api/user/notifications/limit/7",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNotification(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNotification();
    // console.log(notification);
  }, []);

  const userJSON = localStorage.getItem("user");
  const user = JSON.parse(userJSON);

  useEffect(() => {
    // Add or remove the 'arabic' class to the body based on the current language
    if (i18n.language === "ar") {
      document.body.classList.add("arabic");
      document.documentElement.setAttribute('dir', 'rtl'); 

    } else {
      document.body.classList.remove("arabic");
      document.documentElement.removeAttribute('dir'); 
    }
  }, [i18n.language]);

   // Close dropdowns when route changes
   useEffect(() => {
    setActiveDropdown(null);
    setNavbarOpen(false);
    $("#notification-dropdown").slideUp("slow");
    $("#language-dropdown").slideUp("slow");
    $(".logout-div").slideUp("slow");
  }, [location.pathname]); 

  return (
    <div className={`container-fluid ${i18n.language === "ar" ? "arabic" : ""}`}>
      <p className="navbar-brand mb-0 h1 fw-bolder px-4 py-4">{t("tech")}</p>

      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-label="Toggle navigation"
        aria-expanded={navbarOpen}
        onClick={handleToggleNavbar}
        
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse ${i18n.language === "ar" ? "justify-content-start" : "justify-content-end"} ${navbarOpen ? 'show' : ''}`}
        id="navbarSupportedContent"
      >
        <ul
          className={`navbar-nav mb-lg-0 d-flex align-items-center ${
            i18n.language === "ar"
              ? "justify-content-start"
              : "justify-content-end"
          }`}
        >
          <div className="navbar-items py-1 me-2 px-2 d-flex justify-content-center align-items-center border rounded-3 ">
            <li className="nav-item w-100">
              <Link
                className="nav-link "
                onClick={notiHandler}
                to="#"
                id="notification-icon"
              >
                <i className="fa-solid fa-bell nav-icon-color"></i>
              </Link>
              <div id="notification-dropdown">
                {notification.length === 0 ? (
                  <p className="text-center mt-3">{t("noNotification")}</p>
                ) : (
                  <div>
                    <ul className="notification-list list-unstyled p-2 pb-0">
                      {Object.entries(notification).map(
                        ([date, items], index) => (
                          <NotificationItem
                            date={date}
                            key={index}
                            items={items}
                          />
                        )
                      )}
                    </ul>
                    <div className="text-end mb-2">
                      <Link
                        to={"/layout/notification"}
                        onClick={()=>setNavbarOpen(false)}
                        className="p-2 text-dark w-100 mx-auto pt-0 fw-bold"
                      >
                        {t("seeAll")} <img src="/Images/arrow-right.png" alt="arrow" />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </li>
          </div>
          <div className="navbar-items  d-flex justify-content-center align-items-center flex-column me-2 ">
            <li className="nav-item w-100 border rounded-3 py-1 px-2">
              <Link
                className="nav-link "
                to="#"
                id="language-switcher"
                onClick={changeLanguage}
              >
                <i className="fa-solid fa-globe"></i>
              </Link>
            </li>
          </div>
          <div id="language-dropdown" className="language-dropdown mt-1">
            <LanguageSwitcher onClick={()=> {changeLanguage(); setNavbarOpen(false)}} />
          </div>
          <div className="navbar-items py-1 me-2 px-2 d-flex justify-content-center align-items-center border rounded-3">
            <li className="nav-item w-100">
              <Link
                className="nav-link d-flex justify-content-around"
                to="/layout/wallet" onClick={() => setNavbarOpen(false)}
              >
                <img
                  src="/Images/wallet.png"
                  className="w-25 me-2"
                  alt="wallet"
                />{" "}
                {t("wallet")}
              </Link>
            </li>
          </div>
          <div className=" me-2 px-1 d-flex justify-content-center align-items-center border rounded-3 w-25">
            <li className="nav-item w-100 d-flex justify-content-center align-items-center">
              <Link
                className="nav-link d-flex justify-content-around"
                to={"#"}
                onClick={profileHandler}
              >
                <img
                  src="/Images/def.jpg"
                  className="w-25 bg-danger me-1 rounded-circle"
                  alt="profile"
                />
                <p className="m-0 p-0 me-1">{user.firstName}</p>
                <img src="/Images/arrow-down.png" alt="arrow down" />
              </Link>
            </li>
            <div className="logout-div bg-white border mt-3 rounded-3">
              <Link
                className="text-decoration-none h6 text-dark"
                to={"/layout/profile"}
                onClick={() => setNavbarOpen(false)}>
               
                <p className="p-2 m-0 border-bottom">{t("profile")}</p>
              </Link>
              <p className="p-2 m-0 h6 " onClick={logOut}>
                {t("logout")}
              </p>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
}
