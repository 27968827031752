import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
export default function CourseCompletedItem({ course, key }) {
    const { t } = useTranslation();
  return (
    <div key={key} className="col-sm-6 col-lg-4 p-2 mt-3">
    <div>
        <div className="bg-white shadow text-center p-3 rounded-3">
            <img src={course.image.url} className="w-100 home-course" alt="" />
        </div>
        <p className="  mt-3 h4"> {course.name}</p>
        <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 opacity-50 h5 ">{course.teacher.user.fullName}</p>
        </div>
        <div className=" position-relative mt-2">
            <Link to={`/layout/coursedetails/${course.id}`}>
                      <button className=" border-0 bg-enrooled-course py-2 w-100 fw-bold rounded-pill start-course">{ t("completed")}</button>
                <div className=" completed-arrow bg-warning rounded-circle shadow">
                    <div className=" w-100 h-100  d-flex justify-content-center align-items-center">
                        <img src="/Images/completed.png" alt="" />
                    </div>
                </div>
            </Link>
        </div>
    </div>
  </div>
  )
}
