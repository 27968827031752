import React, { useEffect, useRef, useState } from 'react'
import { json, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import $ from 'jquery'; 
import ReactHelmet from '../ReactHelmet/ReactHelmet';

export default function EditPassword() {
    const { t, i18n } = useTranslation();
  const inputRefs = useRef([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const [isloading, setIsloading] = useState(false);
    const [succes, setSucces] = useState();
    const [errors, setErrors] = useState();
    const [emailErrors, setEmailErrors] = useState();
    const [verifyErrors, setVerifyErrors] = useState();
    const [user, setUser] = useState();
    const goBack = () => {
        navigate(-1);
    };
    useEffect (() => {
        getUser(); 
    },[])
    const getUser = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        setUser(user);
        console.log(user);
    }
    const handlePassword = async (e) => {
        const user = JSON.parse(localStorage.getItem("user"));
        const edit_email = document.getElementById('email').value;
        let send_data = {
            email : edit_email
        };
        e.preventDefault();
      console.log(user.email);
      console.log(edit_email);
      if (user.email===edit_email) {
        $('#submitButton').attr('data-bs-target', '#verifycode');
        $('#submitButton').attr('data-bs-toggle', 'modal');
      }
      else {
        setEmailErrors("This Email Is Invalid");
      }
        
        try {
            const token = localStorage.getItem("token");
            let { data } = await axios.post("https://back.ig-way.com/api/auth/password/reset",send_data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(data);
          
        } catch (error) {
            console.log(error);
        }
    }
    const handleSubmit = async (values, e) => {
        
        setIsloading(true);
      try {
            const token = localStorage.getItem("token");
          
            const inputString = values.inputs.join(''); 
            let code = {"verification_code": inputString, "user_id": user.id};
            let { data } = await axios.post(`https://back.ig-way.com/api/auth/password/otp-check`, code,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        localStorage.setItem("token_verify",data.token)
        setIsloading(false);
            
            navigate('/layout/usernewpassword');
            window.location.reload();
        } catch (error) {
          setVerifyErrors(error.response.data.message?error.response.data.message:error.response.data.errors.message[0])
          console.log(error);
          setIsloading(false);
          
        }
        
        
        
    };
    const handleForgetPassword = async (e) => {
        e.preventDefault();
        let old_password=$("#old_password").val();
        let new_password=$("#new_password").val();
        let password_confirmation=$("#password_confirmation").val();
        if (new_password===password_confirmation) {
          
          let data_send = {
            "old_password": old_password,
            "password":new_password,
            "password_confirmation":password_confirmation
          }
          console.log(data_send);
          try {
            const token = localStorage.getItem("token");
            let { data } = await axios.post(
                `https://back.ig-way.com/api/user/update-password`,data_send,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            
            setSucces(data.message);
            setErrors(null);
           
            
          } catch (err) {
            setErrors(err.response.data.message?err.response.data.message:err.response.data.errors.message[0]);
            setSucces(null);
        }
        }
        else {
          setErrors("Password must be same of password confirmation");
        }
    }
    
    const formik = useFormik({
        initialValues: {
          inputs: Array(4).fill(''),
        },
        validationSchema: Yup.object({
          inputs: Yup.array().of(
            Yup.string().required(t('required'))
          ).min(4, t('fill')).max(4, t('fill')),
        }),
        onSubmit: handleSubmit
    });
    const handleKeyUp = (e, index) => {
        if (e.key === 'Backspace' && index > 0) {
          inputRefs.current[index - 1].focus();
        }
      };
    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (value.length === 1 && index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].focus();
        }
        const newValues = [...formik.values.inputs];
        newValues[index] = value;
        formik.setFieldValue('inputs', newValues);
    };
  const allInputsFilled = formik.values.inputs.every(input => input.trim() !== '');
    
    return <>
        <ReactHelmet title={`${t("forgetPass")} | IG WAY`}/>
        <div className="container">
        <div className="d-flex mb-3 align-items-center">
            <i className="fa-solid fa-arrow-left backIcon" onClick={goBack}></i>
            <h4 className="mx-2">Create new password</h4>
          </div>
        <p>Your new password must be different to previously used passwords</p>
        {errors != null ? <div className='alert alert-danger w-50'> { errors}</div>:""}
        {succes != null ? <div className='alert alert-success w-50'> {succes}</div> : ""}
        <div className="row">
          <div className="col-md-6">

            <div className="border bg-white p-4 rounded-3">
                    <div className="mb-3">
                        <label  htmlFor="password" className="form-label forget-password">
                            Old Password
                        </label>
                        <div className="input-group">
                            <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            id='old_password'
                            className="form-control pe-5"
                            placeholder="Enter Your Old Password"
                                        />
                                            
                            
                            <button
                            className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                            type="button "
                                onClick={(event) => { 
                                event.preventDefault();
                                setShowPassword(!showPassword)
                                } }
                            >
                            {showPassword ? <i className=" fa-regular fa-eye"></i> : <i className=" fa-solid fa-eye-slash"></i>}
                            </button>
                        </div>
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label forget-password">
                            New Password
                        </label>
                        <div className="input-group">
                            <input
                            type={showPassword ? 'text' : 'password'}
                            id='new_password'
                            className="form-control pe-5"
                            placeholder="Enter Your New Password"
                                        />
                                            
                            
                            <button
                            className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                            type="button "
                                onClick={(event) => { 
                                event.preventDefault();
                                setShowPassword(!showPassword)
                                } }
                            >
                            {showPassword ? <i className=" fa-regular fa-eye"></i> : <i className=" fa-solid fa-eye-slash"></i>}
                            </button>
                        </div>
                    </div>
                    
                    <div className="mb-3">
                      <label htmlFor="password_confirmation" className="form-label forget-password">{t('confirmPass')}</label>
                        <div className="input-group">
                      <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      id='password_confirmation' 
                      className="form-control pe-5" 
                      placeholder={t('passConfirmationPlaceholder')}
                        />
                        <button
                          className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                          type="button "
                          onClick={(event) => { 
                            event.preventDefault();
                            setShowConfirmPassword(!showConfirmPassword);
                          } }
                        >
                          {showConfirmPassword ? <i className=" fa-regular fa-eye"></i> : <i className=" fa-solid fa-eye-slash"></i>}
                          </button>
                        </div>

                      
                    
                      </div>
                    <div className='text-center mt-4 email'>
                        <p 
                        className='forget-password btn'
                        data-bs-toggle="modal"
                        data-bs-target="#forgetpasswordmodal"
                        >
                        Forgot your password?
                        </p>
                        <div
                    className="modal fade"
                    id="forgetpasswordmodal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="exampleModalLabel">
                            Change Password
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="p-3">    
                        {emailErrors != null ? <div className='alert alert-danger'>{ emailErrors}</div>:""}
                            <div className="mb-3 text-start">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    {t('Email')}
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter Your Email"
                                    name="email"
                                />
                                </div>
                                <button
                                id="submitButton"
                                className='btn btn-warning w-100' 
                                onClick={handlePassword} 
                                
                                >
                                {t('submit')}
                                </button>
                        </div>
                        </div>
                    </div>
                  </div>
                    </div>
                    <div className='text-center verify'>
                  <form className="row" onSubmit={formik.handleSubmit}>
                        
                        <div
                    className="modal fade"
                    id="verifycode"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="exampleModalLabel">
                          OTP Verification
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="p-3">  
                          {verifyErrors!=null?<div className='alert alert-danger'> {verifyErrors}</div>:""}
                            <p className='opacity-75 m-0 p-0'>
                            Please Enter The four digit code sent to
                            </p>  
                            <p className='p-0 m-0'>
                                {user?.email?user.email:""}
                            </p>
                            <div className="row text-center">
                            {[...Array(4)].map((_, index) => (
                              <div className="col-md-3" key={index}>
                                <input
                                  type="text"
                                  className="border-bottom-custom text-center"
                                  maxLength="1"
                                  ref={(el) => (inputRefs.current[index] = el)}
                                  onChange={(e) => handleInputChange(e, index)}
                                  onKeyUp={(e) => handleKeyUp(e, index)}
                                  value={formik.values.inputs[index]}
                                />
                              </div>
                            ))}
                          </div>
                          {isloading ? <button type="button" className="btn btn-warning w-100 p-3 m-0 mt-5"><i className="fas fa-spinner fa-spin"></i></button>
                          : <button type="submit" className="btn btn-warning w-100 p-3 mt-5 m-0" disabled={!allInputsFilled}>
                        
                            {t('next')}
                          </button>}
                        </div>
                        </div>
                    </div>
                  </div>
                  </form>
                    </div>
                    <button type='submit' className='btn btn-warning w-100' onClick={handleForgetPassword}>
                        {t('submit')}
                    </button>
            </div>
          </div>
        </div>
            {/* </form> */}

        </div>
    </>
}
