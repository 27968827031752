import React, { useEffect, useState, useMemo, useCallback } from "react";
import { addDays, subDays, format, startOfWeek } from "date-fns";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CourseHomeItem from "../Courses/CourseItem/CourseHomeItem";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Circles } from "react-loader-spinner";
import ReactHelmet from "../ReactHelmet/ReactHelmet";
import { useWallet } from "../../WalletContext";

export default function Home() {
  const { t } = useTranslation();
  const location = useLocation();
  const [homeCourses, setHomeCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [adds, setAdds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userJSON = localStorage.getItem("user");
  const user = JSON.parse(userJSON);
  const { wallet, fetchWalletData } = useWallet();
  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth <= 572) {
        setSlidesToShow(1);
      } else if (window.innerWidth <= 1150) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(4);
      }
    };

    window.addEventListener("resize", updateSlidesToShow);
    updateSlidesToShow();

    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow,
      slidesToScroll: 1,
    }),
    [slidesToShow]
  );

  const settings2 = useMemo(
    () => ({
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }),
    []
  );

  const [currentStartDate, setCurrentStartDate] = useState(
    startOfWeek(new Date())
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const [addsResponse, coursesResponse, enrolledCoursesResponse] =
          await Promise.all([
            axios.get("https://back.ig-way.com/api/ads", {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get("https://back.ig-way.com/api/courses/limit/8", {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get("https://back.ig-way.com/api/user/enrolled-courses", {
              headers: { Authorization: `Bearer ${token}` },
            }),
            fetchWalletData(),
          ]);
        console.log(user.student);
        setAdds(addsResponse.data.data);
        setHomeCourses(coursesResponse.data.data);
        setEnrolledCourses(enrolledCoursesResponse.data.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [fetchWalletData]);

  const handlePrevWeek = useCallback(() => {
    setCurrentStartDate(subDays(currentStartDate, 7));
  }, [currentStartDate]);

  const handleNextWeek = useCallback(() => {
    setCurrentStartDate(addDays(currentStartDate, 7));
  }, [currentStartDate]);

  const getCurrentWeek = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(addDays(currentStartDate, i));
    }
    return days;
  };

  const currentWeek = getCurrentWeek();
  return (
    <>
      <ReactHelmet title={`${t("home")} | IG WAY`} />

      {isLoading === true ? (
        <div className="d-flex justify-content-center align-items-center spiner">
          <Circles
            height="80"
            width="80"
            color="#FFBC15"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className=" container-fluid px-4">
          <div className="row">
            <div className="col-sm-6 col-lg-3 mt-2 container-res">
              <div className=" bg-white rounded-2 border p-2">
                <h6 className=" opacity-50 wallet-title">{t("yourWallet")}</h6>
                <div className="d-flex justify-content-between align-items-baseline py-1">
                  <h2 className="mt-4 wallet-price ">
                    {wallet} <span>EG</span>
                  </h2>

                  <img src="/Images/5.png" className="w-25 mb-auto" alt="" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mt-2 container-res">
              <div className=" bg-white rounded-2 border p-2">
                <h6 className=" opacity-50 wallet-title">
                  {t("walletRechargeTransactions")}
                </h6>
                <div className="d-flex justify-content-between align-items-baseline py-1">
                  <h2 className="mt-4 wallet-price">
                    {user.student.rechargeTransactions} <span>EG</span>
                  </h2>

                  <img src="/Images/2.png" className="w-25 mb-auto" alt="" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mt-2 container-res">
              <div className=" bg-white rounded-2 border p-2">
                <h6 className=" opacity-50 wallet-title">
                  {t("attendanceTransactions")}
                </h6>
                <div className="d-flex justify-content-between align-items-baseline py-1">
                  <h2 className="mt-4 wallet-price">
                    {user.student.attendanceTransactions} <span>EG</span>
                  </h2>

                  <img src="/Images/3.png" className="w-25 mb-auto" alt="" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mt-2 container-res">
              <div className=" bg-white rounded-2 border p-2">
                <h6 className=" opacity-50 wallet-title">{t("Courses")}</h6>
                <div className="d-flex justify-content-between  align-items-baseline py-1">
                  <h2 className="mt-4 wallet-price">
                    {user.student.walletRefundTransactions} <span>EG</span>
                  </h2>

                  <img src="/Images/1.png" className="w-25 mb-auto" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 add">
                {adds.length > 0 ? (
                  <Slider {...settings2}>
                    <div className="col-md-12 mt-4 add bg-home-center-div">
                      <div className="row px-3 ">
                        <div className="col-md-6 rounded-start-2 bg-home-center-div px-4 d-flex flex-column justify-content-center">
                          <h2>{t("bannerHeader")}</h2>
                          <p className="mt-4 h5 add-title">{t("bannerText")}</p>
                          <button className="btn btn-warning mt-4 add-btn px-2 py-2 w-25 fw-bolder">
                            {t("getStarted")}
                          </button>
                        </div>
                        <div className="col-md-6 rounded-end-2  bg-home-center-div ">
                          <img
                            src="/Images/designer-working.png"
                            className=" add-img mx-auto"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {adds.map((add, index) => (
                      <div className="col-md-12 mt-4 add" key={index}>
                        <img
                          src={add.image.url}
                          className="w-100 img-add add-img"
                          alt=""
                        />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className="col-md-12 mt-4 add bg-home-center-div">
                    <div className="row px-3 ">
                      <div className="col-md-6 rounded-start-2 bg-home-center-div p-3 px-4 d-flex flex-column justify-content-center">
                        <h2>{t("bannerHeader")}</h2>
                        <p className="mt-4 h5">{t("bannerText")}</p>
                        <button className="btn btn-warning mt-4 px-2 py-2 w-25 fw-bolder">
                          {t("getStarted")}
                        </button>
                      </div>
                      <div className="col-md-6 rounded-end-2  bg-home-center-div text-center">
                        <img
                          src="/Images/designer-working.png"
                          className=""
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                )}
                <h2 className="mt-2 ">{t("myCourses")}</h2>
              </div>
              {enrolledCourses.length > 0 ? (
                <div className="col-md-12">
                  <div className="container">
                    <div className="row">
                      <Slider {...settings}>
                        {enrolledCourses.map((course, index) => (
                          <CourseHomeItem course={course} key={index} />
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-12 px-4">
                  <div className="container">
                    <div className="row rounded-3 bg-white border py-4">
                      <div className="col-md-6  text-center">
                        <img src="/Images/course-div.png" className="" alt="" />
                      </div>
                      <div className="col-md-6 p-3 px-4 d-flex flex-column justify-content-center align-items-center">
                        <h4>{t("startExploring")}</h4>
                        <h4>{t("learningJourney")}</h4>
                        <button className="btn btn-warning mt-4 px-2 py-2 w-25 fw-bold">
                          {t("startExploring")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <h2 className="mt-4 ">{t("coursesOffer")}</h2>
              {homeCourses.length > 0 ? (
                <div className="col-md-12 px-4">
                  <div className="container">
                    <div className="row">
                      <Slider {...settings}>
                        {homeCourses.map((course, index) => (
                          <CourseHomeItem course={course} key={index} />
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-12 px-4">
                  <div className="container">
                    <div className="row rounded-3 bg-white border py-4">
                      <div className="col-md-6  text-center">
                        <img src="/Images/course-div.png" className="" alt="" />
                      </div>
                      <div className="col-md-6 p-3 px-4 d-flex flex-column justify-content-center align-items-center">
                        <h4>{t("startExploring")}</h4>
                        <h4>{t("learningJourney")}</h4>
                        <button className="btn btn-warning mt-4 px-2 py-2 w-25 fw-bold">
                          {t("startExloring")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="container-fluid mt-5 rounded-3 p-3 bg-white border">
                  <h5 className="mb-4">
                    {" "}
                    {format(currentStartDate, "MMMM  yyyy")}{" "}
                    <i className="fa-regular fa-calendar-days"></i>
                  </h5>
                  <div className="row">
                    <div className="col-md-1 d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-between align-items-center mb-3 ">
                        <button
                          className="btn border rounded-circle d-flex justify-content-center cel-btn align-items-center opacity-50"
                          onClick={handlePrevWeek}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        {currentWeek.map((day, index) => (
                          <div key={index} className="col-12 col-md p-1">
                            <div className="border border-warning rounded-3 text-center p-2">
                              <p className="opacity-50 fw-bolder">
                                {format(day, "eee")}
                              </p>
                              <p className="opacity-50 fw-bolder">
                                {format(day, "d")}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-center align-items-center ">
                      <button
                        className="btn border rounded-circle d-flex justify-content-center cel-btn align-items-center"
                        onClick={handleNextWeek}
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
